import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StyledSidebar = styled.div`
    height: 100%;
    width: 100%;
    z-index: 999;
    text-align: right;
    position: relative;
    min-width: 300px;
    
    @media(max-width: 701px) {
      min-width: auto;
    }
    .closeSidebar {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 1.5em;
      color: white;
      opacity: 0.5;
    }
    
    a {
      text-decoration: none;
      color: lightgrey;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    
    .logo-container {
        opacity: 0.5;
        img {
          max-width: 140px;
        }
    }
    
    .menu-container {
      display: grid;
      cursor: pointer;
      padding: 3em 2em 2em 2em;
    }
    
    .social-container {
      display: flex;
      margin-top: 2em;
      align-items: center;
      justify-content: flex-end;
      opacity: 0.5;
      
      .singleItem {
          margin-left: 8px;
          &:hover {
            transform: scale(0.85);
            transition: all 100ms ease-in-out;
          }
      }
    
      img {
        max-width: 16px !important;
        filter: invert(0.9);
        &:hover {
          transform: scale(0.85);
          transition: all 100ms ease-in-out;
        }
      }
    }
    
    h1 {
      color: white;
      transition: color 50ms ease-in;
      letter-spacing: 1px;
      
      &:hover {
        color: black;
        transition: color 50ms ease-in;
      }
    }
`;

function Sidebar(props) {

    return (
        <StyledSidebar>
            <div onClick={() => props.onClose()} className={'closeSidebar'}><FontAwesomeIcon icon={'times'}/></div>
            <div className={'menu-container'}>
                <Link to={"/"}><h1>Home</h1></Link>
                <a href={'https://parkevent.club-toolkit.com'} to={"/"}><h1>Programm</h1></a>
                <a href={'https://parkevent.club-toolkit.com'}><h1>Tickets</h1></a>
                <Link to={"/location"}><h1>Anfahrt</h1></Link>
                <Link to={"/imprint"}><h1>Impressum</h1></Link>
            </div>
        </StyledSidebar>
    )
}

export default Sidebar;