import React from "react";
import styled from "styled-components";
import handwriting1 from "../../styles/location.png";

const StyledImprint = styled.div`
    height: 100%;
    width: 100%;
`;

const StyledLocationContainer = styled.div`
  padding: 2em;
  
  img {
    width: 100%;
  }
`;

function Location() {

    return (
        <StyledImprint>
            <StyledLocationContainer>
                <img alt="location" src={handwriting1}/>
            </StyledLocationContainer>
        </StyledImprint>
    )
}

export default Location;