import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout01 from "./components/layouts/01_layout";
import Layout02 from "./components/layouts/02_layout";
import Layout03 from "./components/layouts/03_layout";

function Routes() {
    return (
        <React.Suspense fallback={'Loading'}>
            <Router>
                <Switch>
                    <Route exact path={'/'} render={ () => <Layout01/> }/>
                    <Route exact path={'/location'} render={ () => <Layout03/> }/>
                    <Route exact path={'/imprint'} render={ () => <Layout02/> }/>
                </Switch>
            </Router>
        </React.Suspense>
    );
}

export default Routes;