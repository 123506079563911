import React from "react";
import styled from "styled-components";
import handwriting1 from "../../styles/handwriting-3.png";

const StyledImprint = styled.div`
    height: 100%;
    width: 100%;
`;

const HandwritingItem = styled.div`
  padding: 3em 2em;
  display: flex;
  justify-content: flex-end !important;
  background-color: #efefec;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;

  @media(max-width: 701px) {
      padding: 1em;
      justify-content: center !important;
      
      img {
        max-width: 200px !important;
        height: auto !important;
        object-fit: contain;
      }
  }
  
  img {
    max-width: 200px;
  }
`;

const HandwritingItemLeft = styled(HandwritingItem)`
  justify-content: flex-start !important;
`;

const ImprintContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 4em;
  max-width: 1500px;
  margin-left: auto;
  padding: 2em;
  margin-right: auto;
  padding-bottom: 6em;
  
  @media(max-width: 701px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
  }
`;

const SingleImprint = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 2em;
  text-align: left;
  
  h3 {
     font-weight: 600;
     color: #080e22;
  }
  
  @media(max-width: 901px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledText = styled.div`
  h3 {
    color: slategrey;
    font-weight: 500
  }
`;


function Imprint() {

    return (
        <StyledImprint>
            <HandwritingItemLeft><img alt="hand" src={handwriting1}/></HandwritingItemLeft>
            <ImprintContainer>
                <SingleImprint>
                    <h3>Park Events</h3>
                    <StyledText>
                        <h3>TTE Gastrobetriebs GmbH</h3>
                        <h3>Berliner Straße 5</h3>
                        <h3>37073 Göttingen</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Vertreten durch</h3>
                    <StyledText>
                        <h3>Markus Thunert</h3>
                        <h3>Steffen Thunert</h3>
                        <h3>Denis Eydner</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Büro</h3>
                    <StyledText>
                        <h3>Grotefend-Hinterhaus</h3>
                        <h3>Berliner Straße 1</h3>
                        <h3>37073 Göttingen</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Ansprechpartner</h3>
                    <StyledText>
                        <h3>Denis Eydner</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Mail</h3>
                    <StyledText>
                        <h3>post@club-savoy.de</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Handelsregister</h3>
                    <StyledText>
                        <h3>Registergericht: Göttingen</h3>
                        <h3>Registernummer: HRB 205370</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Umsatzsteuer-ID</h3>
                    <StyledText>
                        <h3>§27 a Umsatzsteuergesetz: DE319920439</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Programmierung</h3>
                    <StyledText>
                        <h3>Alexander Thunert</h3>
                        <h3>info@munichfeels.de</h3>
                    </StyledText>
                </SingleImprint>
                <SingleImprint>
                    <h3>Grafik</h3>
                    <StyledText>
                        <h3>Giulian Thunert</h3>
                        <h3>giulian@club-savoy.de</h3>
                    </StyledText>
                </SingleImprint>
            </ImprintContainer>
        </StyledImprint>
    )
}

export default Imprint;