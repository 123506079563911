import React from 'react';
import styled from "styled-components";
import Start_BG from '../../styles/hp-home-bg.jpg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Logo3 from '../../styles/logo-1.png';
import Logo2 from '../../styles/logo-2.png';
import Logo1 from '../../styles/logo-3.png';
import Logo4 from '../../styles/logo-4.png';
import Logo5 from '../../styles/logo-5.png';
import Logo6 from '../../styles/logo-6.png';
import Logo7 from '../../styles/logo-7.png';
import Logo8 from '../../styles/logo-8.png';
import kaufpark from '../../styles/kaufpark.png';
import handwriting1 from '../../styles/handwriting-1.png';
import handwriting2 from '../../styles/handwriting-2.png';
import handwriting4 from '../../styles/handwriting-4.png';
import location from "../../styles/location.png";

const StyledHome = styled.div`
    height: 100%;
    width: 100%;
`;

const StyledWelcomeGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 1em;
    min-height: 30vh;
    padding: 2em 2em 2em 2em;
    z-index: 2 !important;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #efefec;
    
    a {
      text-decoration: none;
    }
    
    @media(max-width: 901px) {
      padding: 2em 1em 2em 1em;
      grid-gap: 1em;
      min-height: 60vh;
      grid-template-columns: repeat(2,1fr);
    }
    
`;

const StyledLocationGrid = styled.div`
  display: none;
  background-color: #efefec;
  @media(max-width: 901px) {
      display: block !important;
      position: sticky;
      position: -webkit-sticky;
      top: 50px;
      z-index: -1 !important;
      
      .location {
      img {
        max-width: 100px !important;
      }
      }
    }
    
    .location {
      
    }
`;

const StyledLocationImage = styled.div`
  img {
    width: 100%;
  }
  padding: 2em;
`;

const StyledWelcomeImage = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${ Start_BG });
    background-position: center;
    background-size: cover;
    min-height: 50vh;
    
    @media(max-width: 501px) {
      min-height: 35vh;
      position: sticky;
      position: -webkit-sticky;
      top: 50px;
      z-index: -1 !important;
    }
`;

const styledItem = styled.div`
    background: #3449f6;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 100ms ease-in;
    border-radius: 6px;
    height: 100%;

    h2, h4 {
      color: white
    }
    
    .icon {
      color: white;
      margin-bottom: 0.5em;
      font-size: 2em;
    }
    
    &:hover {
      transform: scale(0.95);
      transition: all 100ms ease-in;
    }
    
    .soon {
      
    }
`;

const StyledItem1 = styled(styledItem)`
`;

const StyledItem2 = styled(styledItem)`

  .next {
      background-color: #080e22;
      padding: 0.5em;
      border-radius: 4px;
  }
`;

const StyledItem3 = styled(styledItem)`
`;

const StyledItem4 = styled(styledItem)`
`;

const StyledLogos = styled.div`
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-column-gap: 1em;
    padding: 1em 0 8em 0;
    z-index: 2 !important;
    background-color: #efefec;
     max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 200px;
    }
    
    @media(max-width: 1001px) {
      grid-template-columns: repeat(3,1fr);
    }
    
    @media(max-width: 901px) {
      padding: 2em 0 6em 0;
      grid-gap: 1em;
      min-height: 60vh;
      grid-template-columns: repeat(2,1fr);
    }
`;

const LogoItem = styled.div`
  width: auto;
  opacity: 0.3;
  transition: all 200ms ease-in;
  cursor: pointer;
  
  &:hover {
    opacity: 1;
    transition: all 200ms ease-in;
  }
`;

const StyledWelcomeText = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 3em;
    min-height: 40vh;
    padding: 1em 0;
    width: 100%;
    z-index: 2 !important;
    background-color: #efefec;

    @media(max-width: 901px) {
      grid-template-columns: 1fr !important;
      min-height: auto !important;
      padding: 1em 0 !important;
    }
    
    .welcomeText {
      background-color: white;
      display: grid;
      
      .textContainer {
        padding: 4em;
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 1em;
        text-align: left;
        max-width: 80%;
        justify-self: right;
        
        @media(max-width: 901px) {
          max-width: none;
          padding: 3em 2em;
        }
      }
      
      h1 {
       color: #3449f6;
       line-height: 1;
      }
    }
    
    .welcomeImage {
      background-image: url(${ kaufpark });  
      background-position: center; 
      background-size: cover;
      
      @media(max-width: 901px) {
        display: none;
      }
    }
    
    a {
      text-decoration: none;
    }
    
    @media(max-width: 901px) {
      padding: 2em 1em 2em 1em;
      grid-gap: 1em;
      min-height: 60vh;
      grid-template-columns: repeat(2,1fr);
    }
    
`;

const HandwritingItem = styled.div`
  padding: 3em 2em;
  display: flex;
  justify-content: flex-end !important;
  background-color: #efefec;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  
  @media(max-width: 701px) {
      padding: 1em;
      justify-content: center !important;
      
      img {
        max-width: 300px !important;
        height: auto !important;
        object-fit: contain;
      }
  }
  
  img {
    max-width: 500px;
  }
`;

const HandwritingItemLeft = styled(HandwritingItem)`
  justify-content: flex-start !important;
`;

const StyledAgeText = styled.div`
   background: #3449f6;
   color: white;
   text-align: center;
   padding: 1em;
`;



function Home() {

    return (
        <StyledHome>
            <StyledWelcomeImage></StyledWelcomeImage>
            <StyledAgeText>
                <h4>
                    Für unsere Events gibt es keine Altersbeschränkung. Es gelten die gesetzlichen Vorgaben.
                    <span role="img" aria-label="emoji">👪</span>
                </h4>
            </StyledAgeText>
            <StyledWelcomeGrid>
                <a href={'https://parkevent.club-toolkit.com'}>
                    <StyledItem1>
                            <FontAwesomeIcon className={'icon'} icon="calendar-check" />
                            <h2>Programm</h2>
                    </StyledItem1>
                </a>
                <a href={'https://parkevent.club-toolkit.com'}>
                    <StyledItem2>
                            <FontAwesomeIcon className={'icon'} icon="ticket-alt" />
                            <h2>Tickets</h2>
                    </StyledItem2>
                </a>
                <a href={'https://www.instagram.com/park.events'} target={'_blank'} rel="noopener noreferrer">
                <StyledItem3>
                    <FontAwesomeIcon className={'icon'} icon={['fab', 'instagram']} />
                    <h2>Instagram</h2>
                </StyledItem3>
                </a>
                <a href={'https://www.facebook.com/parkevents.goe/photos'} target={'_blank'} rel="noopener noreferrer">
                    <StyledItem4>
                        <FontAwesomeIcon className={'icon'} icon="camera-retro" />
                        <h2>Fotos</h2>
                    </StyledItem4>
                </a>
            </StyledWelcomeGrid>
            <StyledLocationGrid>
                <HandwritingItem className={'location'}><img alt="hand" src={handwriting4}/></HandwritingItem>
                <StyledLocationImage>
                    <img alt="location" src={location}></img>
                </StyledLocationImage>
            </StyledLocationGrid>
            <HandwritingItem><img alt="hand" src={handwriting1}/></HandwritingItem>
            <StyledWelcomeText>
                <div className={'welcomeText'}>
                    <div className={'textContainer'}>
                        <h1>Göttinger Zusammenhalt</h1>
                        <h4>Park-Events ist ein Gemeinschaftsprojekt mehrerer Göttinger Unternehmer & Veranstalter.</h4>
                        <h4>Ab dem 29.05.2020 werden auf dem Gelände des Kauf Parks Autodisco, -Konzerte & weitere Veranstaltungen stattfinden.</h4>
                        <h4>Alle weiteren Infos findest Du demnächst hier.</h4>
                    </div>
                </div>
                <div className={'welcomeImage'}>
                </div>
            </StyledWelcomeText>
            <HandwritingItemLeft><img alt="hand" src={handwriting2}/></HandwritingItemLeft>
            <StyledLogos>
                <a href={'https://www.kauf-park.de'} target={'blank'}><LogoItem><img alt="logo" src={Logo1}/></LogoItem></a>
                <a href={'https://www.spk-goettingen.de/de/home.html'} target={'blank'}><LogoItem><img alt="logo" src={Logo8}/></LogoItem></a>
                <a href={'https://www.club-savoy.de'} target={'blank'}><LogoItem><img alt="logo" src={Logo2}/></LogoItem></a>
                <a href={'https://www.jc-eventtechnik.de'} target={'blank'}><LogoItem><img alt="logo" src={Logo3}/></LogoItem></a>
                <a href={'https://www.junges-theater.de'} target={'blank'}><LogoItem><img alt="logo" src={Logo4}/></LogoItem></a>
                <a href={'https://www.media-va.de'} target={'blank'}><LogoItem><img alt="logo" src={Logo5}/></LogoItem></a>
                <a href={'https://www.stadtwerke-goettingen.de/'} target={'blank'}><LogoItem><img alt="logo" src={Logo6}/></LogoItem></a>
                <a href={'https://www.redbull.com/'} target={'blank'}><LogoItem><img alt="logo" src={Logo7}/></LogoItem></a>
            </StyledLogos>
        </StyledHome>
    )
}

export default Home;