import React from "react";
import Header from "../header/header";
import CopyRightBar from "../copyright/copyrightBar";
import Home from '../content/home';

function Layout01() {

    return (
        <div>
            <Header/>
            <Home/>
            <CopyRightBar/>
        </div>
    )
}

export default Layout01;