import React from "react";
import Header from "../header/header";
import CopyRightBar from "../copyright/copyrightBar";
import Location from "../content/location";

function Layout03() {

    return (
        <div>
            <Header/>
            <Location/>
            <CopyRightBar/>
        </div>
    )
}

export default Layout03;