import React from "react";
import Header from "../header/header";
import CopyRightBar from "../copyright/copyrightBar";
import Imprint from "../content/imprint";

function Layout02() {

    return (
        <div>
            <Header/>
            <Imprint/>
            <CopyRightBar/>
        </div>
    )
}

export default Layout02;