import React from 'react';
import { StyledCopyrightBar } from './styledCopyrightBar';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, Link } from 'react-router-dom';

function CopyRightBar(props) {
    const currentYear = moment().format('YYYY');
    const location = props.history.location.pathname;

    const isSelected = (path) => {
        return location === path;
    };

    return (
        <StyledCopyrightBar>
            <div className={'mobileMenu'}>
                <Link to={"/"} className={ isSelected('/') ?'mobileMenu__item mobileMenu__item__selected' : 'mobileMenu__item'}><h5>Home</h5></Link>
                <Link to={"/program"} className={isSelected('/gutscheine') ?'mobileMenu__item mobileMenu__item__selected' : 'mobileMenu__item'}><h5>Programm</h5></Link>
            </div>
            <div className={'container'}>
                <h5>© { currentYear } | Göttinger Park Events</h5>
                <div className={'container__socials'}>
                    <a href={'https://www.instagram.com/park.events/'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'instagram']} /></a>
                    <a href={'https://www.facebook.com/parkevents.goe'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'facebook']} /></a>
                    <a href={'mailto:post@club-savoy.de'}><FontAwesomeIcon className={'singleItem'} icon={'envelope'}/></a>
                </div>
            </div>
        </StyledCopyrightBar>
    )
}

export default withRouter(CopyRightBar);