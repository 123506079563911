import React, {useState} from 'react';
import { StyledHeader, StyledSocialsContainer, StyledBurgerMenu } from './styledHeader';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Logo from '../../styles/GC-Logo.png';
import { Link, withRouter } from 'react-router-dom';
import Panel from '../sidebar/Panel';

function Header(props) {
    let [isPanelOpened, setPanelState] = useState(false);
    const location = props.history.location.pathname;

    const isSelected = (path) => {
        return location === path;
    };

    return (
        <StyledHeader>
            <div className={'container'}>
                <Link to={'/'} className={'container__name'}>
                    <div className={'container__name__logo'}><img alt={'logo'} src={Logo}/></div>
                </Link>
                <div className={'container__menu'}>
                    <Link to={'/'} className={ isSelected('/') ?'container__menu__item container__menu__item__selected' : 'container__menu__item'}><h5>Home</h5></Link>
                </div>
                <StyledSocialsContainer>
                    <a href={'https://www.instagram.com/park.events/'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'instagram']} /></a>
                    <a href={'https://www.facebook.com/parkevents.goe'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'facebook']} /></a>
                    <a href={'mailto:post@club-savoy.de'}><FontAwesomeIcon className={'singleItem'} icon={'envelope'}/></a>
                </StyledSocialsContainer>
                <StyledBurgerMenu onClick={ () => setPanelState(true) }>
                    <FontAwesomeIcon className={'singleItem'} icon="bars" />
                </StyledBurgerMenu>
            </div>
            <Panel
                isOpened={ isPanelOpened }
                onClose={ () => setPanelState(false) }
            >
            </Panel>
        </StyledHeader>
    )
}

export default withRouter(Header);