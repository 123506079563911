import styled from 'styled-components';

const StyledCopyrightBar = styled.div`
  background: #164fd0;
  color: lightgrey;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  
  .mobileMenu {
    display: grid;
    grid-template-columns: auto auto;
    background-color: #101b43;
    display: none;
    a {
      text-decoration: none;
    }
    
    &__item {
      &__selected {
        border-top: 2px solid white;
        color: white;
      }
      color: grey;
      padding: 1em 0;
    }
  }
  .container {
   max-width: 1500px;
   margin-right: auto;
   margin-left: auto;
   display: grid;
   grid-template-columns: auto 1fr;
   align-items: center;
   padding: 1em 2em;
   
   a {
    color: white;
   }
   
   &__socials {
    display: grid;
    justify-self: right;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.5em;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    
    a {
      color: lightgrey;
    }
   
    .singleItem {
    
    img {
      max-width: 16px;
      filter: invert(0.9);
      display: flex;
      align-self: center;
      &:hover {
        transform: scale(0.85);
        transition: all 100ms ease-in-out;
      }
    }
      &:hover {
        transform: scale(0.85);
        transition: all 100ms ease-in-out;
      }
    }
   }
  }
  
  @media(min-width: 601px) {
    .mobileMenu {
      display: none;
    }
  }
  .container {
    padding: 1em;
   }
`;

export { StyledCopyrightBar };